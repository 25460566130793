@import '../../../styles/styles.scss';

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(59, 59, 59, 0.12);
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  border-radius: 20px;
  background: #fff;
  padding: 32px 24px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.closeButton {
  @include closeButton;
}

.title {
  color: #3b3b3b;
  text-align: center;
  font-family: Inter Tight;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 133.333% */
}

.form {
  display: flex;
  flex-direction: column;
  min-width: 452px;
}
.label {
  color: #3b3b3b;
  font-family: Inter Tight;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  margin-bottom: 8px;
}

.input {
  width: 100%;
  min-height: 46px;
  border-radius: 8px;
  border: 1px solid rgba(59, 59, 59, 0.4);
  background: #fff;
  color: #3b3b3b;
  font-family: Inter Tight;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  padding-left: 11px;
  margin-bottom: 16px;
  &::placeholder {
    color: rgba(59, 59, 59, 0.4);
    font-family: Inter Tight;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
}

.text {
  min-height: 128px;
  min-width: 100%;
  max-width: 70vw;
  max-height: 50vh;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  width: 50%;
  height: 42px;
  align-self: flex-end;
}

.secondary {
  max-width: 318px;
  margin-bottom: 40px;
}

.blueButton {
  width: 100%;
  @include blueButton;
}

.whiteButton {
  @include whiteButton;
}

.smallBlueButton {
  width: 95px;
  @include blueButton;
}
