@import "../../styles/styles.scss";

.auth {
  display: flex;
}
.imgContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.mainImg {
  max-height: 100vh;
}

.container {
  height: 100vh;
  width: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 48px;
  padding-bottom: 30px;
  justify-content: space-between;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.title {
  font-size: 24px;
  padding-bottom: 10px;
  margin: 20px 0 0 0;
}
.form {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 16px 60px 0px rgba(59, 59, 59, 0.08);
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-bottom: 8px;
  width: 364px;
}
.title {
  color: #3b3b3b;
  text-align: center;
  font-family: Inter Tight;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 128.571% */
}
.inputContainer {
  width: 100%;
  margin: 8px 0;
}
.input {
  width: 100%;
  padding: 4px;
  line-height: 38px;
  border-radius: 10px;
  border-color: #3b3b3b;
  margin-bottom: 16px;
}

.forgotLink {
  color: rgba(59, 59, 59, 0.64);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  text-align: right;
  line-height: 16px; /* 123.077% */
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  margin: 16px 0 12px 0;
}
.button {
  width: 100%;
  margin: 0;

  button {
    width: 100%;
    height: 46px;
    justify-content: center;
    @include blueButton;
  }
}

.whiteButton {
  width: 100%;
  margin: 0;

  button {
    width: 100%;
    height: 46px;
    justify-content: center;
    @include whiteButton;
  }
}

.secondButton {
  width: 100%;
  margin: 0;

  button {
    width: 100%;
    height: 46px;
    border-color: none;
    border-radius: 10px;
    border-radius: 8px;
    border: 1px solid #46509f;
    background: #fff;
    font-size: 16px;
    color: #464f9e;
  }
}
.label {
  color: #3b3b3b;
  font-family: Inter Tight;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  text-align: left;
  margin: 0;
}
.info {
  color: #3b3b3b;
  font-family: Inter Tight;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 140% */
  margin: 0;
  align-self: center;
}
.error {
  color: #db5a5a;
  text-align: center;
  font-family: Inter Tight;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  border-radius: 8px;
  background: #ffeded;
  padding: 8px 0px;
  width: 100%;
  margin-bottom: 16px;
}
.image {
  justify-self: flex-start;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
