@import "../../../styles/styles.scss";

.tokenModal {
  width: 486px !important;
  position: relative;

  .ant-modal-content {
    border-radius: 20px;

    width: 486px;
    padding: 32px 24px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
  }
  .ant-modal-title {
    font-size: 22px;
    margin-bottom: 6px;
  }
  .closeButton {
    @include closeButton;
  }

  .labelText {
    font-family: Inter Tight;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
  .tokenInput {
    margin-top: 8px;
    width: 100%;
    background: #fff;
    height: 46px;
    border-radius: 8px;
    border: 1px solid #3b3b3b66;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  .textarea {
    margin-top: 8px;
    width: 100%;
    background: #fff;
    min-height: 128px;

    border-radius: 8px;
    border: 1px solid #3b3b3b66;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 24px;
  }
  .info {
    p {
      margin: 0;

      font-family: Inter Tight;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      &:first-of-type {
        margin-top: 8px;
      }
    }
  }

  p {
    font-family: Inter Tight;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  .ant-modal-body {
    font-size: 16px;
    line-height: 20px;
    .note {
      display: flex;

      padding: 8px 16px;
      line-height: 12px;

      border-radius: 10px;
      font-size: 12px;
      color: #865dcf;

      background: #e8e0f8;

      img {
        width: 24px;
        margin-right: 10px;
      }
      p {
        font-family: Inter Tight;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;

        margin: 0;
      }
    }
    .stepsTitle {
      margin-top: 30px;
      font-weight: 700;
    }
    .steps {
      padding: 0 20px;
      li {
        margin: 20px 0;
      }
    }
    .help {
      position: relative;
      left: calc(50% - 155px);
      width: 310px;
      height: 46px;
      color: #3b3b3b;
      border: 1px solid #3b3b3b;
      border-radius: 10px;
      font-size: 16px;
      font-weight: 700;
    }
  }

  .slider {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-bottom: 24px;
    p {
      margin: 0;
      font-family: Inter Tight;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
    input {
      display: none;
    }

    input[type="checkbox"] {
      height: 0;
      width: 0;
      visibility: hidden;
    }

    label {
      cursor: pointer;
      text-indent: -9999px;
      width: 46px;
      height: 26px;
      background: grey;
      display: block;
      border-radius: 100px;
      position: relative;
    }

    label:after {
      content: "";
      position: absolute;
      top: 3px;
      left: 4px;
      width: 20px;
      height: 20px;
      background: #fff;
      border-radius: 90px;
      transition: 0.3s;
    }

    input:checked + label {
      background: #bada55;
    }

    input:checked + label:after {
      left: calc(100% - 5px);
      transform: translateX(-100%);
    }

    //   label:active:after {
    //     width: 130px;
    //   }

    // centering
    body {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
    }
  }
  .buttonContainer {
    display: flex;
    align-items: center;
    gap: 24px;
    align-items: flex-end;
    width: 100%;
  }

  .integration {
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-family: Inter Tight;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        margin: 0;
      }
      .demolink {
        display: flex;
        align-items: center;
        gap: 4px;
        font-family: Inter Tight;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        margin: 0;
        color: #3b3b3b33;
      }
    }
    .link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #3b3b3b66;
      background: #f8f8f8;
      padding: 12px;
      height: 46px;
      border-radius: 8px;
      overflow-x: auto;
      overflow-y: hidden;
    }
  }
}

.whiteButton {
  @include whiteButton;
}
.blueButton {
  @include blueButton;
}
