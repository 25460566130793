.rcw-full-screen {
  width: 350px;
  z-index: 0 !important;
  height: 80vh;
}

.rcw-widget-container {
  position: relative;
}
.rcw-conversation-container {
  box-shadow: none;
  border: 1px solid rgba(59, 59, 59, 0.08);
}

.rcw-conversation-container .rcw-header {
  background-color: #fff;
  padding: 0;
  border-radius: 10px 10px 0 0;
}

.rcw-full-screen .rcw-title {
  padding: 16px 0;
  margin: 0;
  margin-bottom: 24px;
  color: #3b3b3b;
  font-family: "Inter Tight";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 133.333% */
}

.rcw-messages-container {
  overflow: auto;
}
.rcw-picker-btn {
  display: none;
}

.rcw-sender {
  justify-content: space-between;
  background-color: #fff;
  border-top: 1px solid rgba(59, 59, 59, 0.08);
}

.rcw-send {
  background-color: transparent;
}

.rcw-client .rcw-message-text {
  background-color: #5cbacf33;
}

.rcw-response .rcw-message-text {
  background-color: #f3f3f3;
}
