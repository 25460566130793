.userModal {
  width: 145px !important;
  top: 80px;
  right: 48px;
  position: absolute;
}
.userModal .ant-modal-content {
  border-radius: 20px;
  height: 70px;
  width: 145px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ant-modal-content .username {
  margin: 0;
  padding: 8px;
  font-size: 12px;
  text-align: center;
  font-weight: 700;
  color: #953695;
  border-bottom: 1px solid #e0e0e0;
}
.ant-modal-content .logout {
  cursor: pointer;
  margin: 0;
  padding: 8px;
  font-size: 12px;
  text-align: center;
}
.ant-modal-content .logout:hover {
  font-weight: 700;
}
