.label {
  margin: 6px 0 4px;
  color: #3b3b3b;
  font-family: "Inter Tight";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.sliderMarks {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}
.span {
  color: rgba(59, 59, 59, 0.6);
  font-family: "Inter Tight";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.slider {
  width: 100%;
  margin: 0 auto;
}
.ant-form-item {
  margin-bottom: 0;
}
.ant-form-item-explain-error {
  margin-bottom: 6px;
}
.slider .ant-slider-rail {
  border-radius: 8px;
  background: #daf4fa;
  height: 8px;
}

.slider .ant-slider-track {
  height: 8px;
  background: #5cbacf;
}

.slider .ant-slider-step {
  display: none;
}
.slider .ant-tooltip-open {
  background-color: red;
}

.slider .ant-slider-handle::after {
  background-color: #fff;
  box-shadow: 0 0 0 1px #5cbacf;
  width: 24px;
  height: 24px;
  top: -6px;
  left: -6px;
}
.ant-slider-handle:hover::after {
  box-shadow: 0 0 0 1px #5cbacf;
  width: 24px;
  height: 24px;
  top: -6px;
  left: -6px;
}
.ant-slider .ant-slider-handle:focus::after {
  width: 24px;
  height: 24px;
  box-shadow: 0 0 0 1px #5cbacf;
  top: -6px;
  left: -6px;
}
.ant-slider .ant-slider-handle:active::after {
  width: 24px;
  height: 24px;
  box-shadow: 0 0 0 1px #5cbacf;
  top: -6px;
  left: -6px;
}
.ant-slider:hover .ant-slider-handle::after {
  width: 24px;
  height: 24px;
  top: -6px;
  left: -6px;
  box-shadow: 0 0 0 1px #5cbacf;
}
.ant-slider:hover .ant-slider-track {
  background-color: #5cbacf;
}

.ant-slider:hover .ant-slider-rail {
  background-color: #daf4fa;
}
.slider .ant-slider-dot {
  background-color: #fff;
  border-radius: 0;
  border: 1px solid #3b3b3b;
  width: 1px;
  height: 10px;
  top: -4px;
}
.ant-slider:hover .ant-slider-dot-active {
  border-color: #5cbacf;
}
