.container {
  width: calc(100% - 256px);
  position: fixed;
  height: 56px;
  min-height: 56px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 50px;
  z-index: 1;
  background: #fff;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.04);
}

.userImage {
  position: relative;
  width: 24px;
  height: 24px;
  margin-left: 24px;
  &:hover {
    cursor: pointer;
  }
}
