@mixin blueButton {
  border: none;
  border-radius: 10px;
  background: linear-gradient(to right, #464e9e, #58b7cc);
  font-size: 16px;
  color: #ffffff;
  display: flex;
  align-items: center;
  border: none;
  gap: 10px;
  padding: 9px 16px;
  font-family: "Inter Tight";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  height: 42px;

  &:hover {
    cursor: pointer;
    background: linear-gradient(90deg, #656cb4 0%, #79cddf 100%);
  }

  &:active {
    background: linear-gradient(90deg, #7880c6 0%, #99ddec 100%);
  }

  &:disabled {
    background: #eaeaea;
    color: #3b3b3b;
  }
}

@mixin whiteButton {
  height: 42px;
  border: 1px solid #46509f;
  border-radius: 10px;
  background: #fff;
  font-size: 16px;
  color: #464f9e;
  text-align: center;
  font-family: "Inter Tight";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  padding: 9px 16px;
  font-family: "Inter Tight";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;

  &:hover {
    background: linear-gradient(90deg, #46509e 0%, #58b5ca 100%);
    color: #fff;
    cursor: pointer;
    border: 1px solid transparent;
  }

  &:active {
    background: linear-gradient(90deg, #656fb5 0%, #78c9dd 100%);
    color: #fff;
    border: 1px solid transparent;
  }
}

@mixin closeButton {
  position: absolute;
  border: none;
  background: transparent;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  padding: 0;
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
}
