@font-face {
  font-family: "Inter Tight";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./fonts/InterTight/InterTight-Regular.ttf");
}

@font-face {
  font-family: "Inter Tight";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("./fonts/InterTight/InterTight-Medium.ttf");
}

@font-face {
  font-family: "Inter Tight";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("./fonts/InterTight/InterTight-SemiBold.ttf");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./fonts/Inter/Inter-Regular.ttf");
}
