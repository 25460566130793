@import '../../styles/styles.scss';

.main_container {
  height: 100vh;
  display: flex;
  width: 100%;
  background-color: #e7e7e7;
}

.inner_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 256px;
  overflow: scroll;
  background: #fafafa;
}
.main {
  padding: 20px 40px;
  padding-top: 76px;
}
.mainHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}
.title {
  margin: 0;
  margin-bottom: 24px;
  color: #3b3b3b;
  font-family: 'Inter Tight';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
}

.content {
  display: flex;
  gap: 40px;
  width: 100%;
}

.contentContainer {
  border-radius: 20px;
  border: 1px solid rgba(59, 59, 59, 0.08);
  background: #fff;
  padding: 24px 24px 40px 24px;
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }
}

.creationForm {
  flex-grow: 1;
}

.label {
  font-weight: bold;
  margin: 6px 0 4px;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
}
.deleteBot {
  font-size: 12px;
  display: flex;
  align-items: center;
  img {
    margin-right: 8px;
  }
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.tab {
  height: 40px;
  padding: 10px 20px;
  display: inline-block;
  color: #000;
  font-family: Inter Tight;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  border: none;
  outline: none;
  &:hover {
    cursor: pointer;
  }
}

.tabSelected {
  border-radius: 8px 8px 0px 0px;
  background: linear-gradient(90deg, #464f9e 0%, #58b6cb 100%);
  color: #fff;
  font-weight: 600;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.label {
  color: #3b3b3b;
  font-family: Inter Tight;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  margin-bottom: 8px;
}

.input {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: #fff;
  height: 40px;
  color: #000;
  font-family: Inter Tight;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.text {
  min-height: 128px;
  min-width: 100%;
  max-width: 70vw;
  max-height: 50vh;
  padding: 12px;
  color: #000;
  font-family: Inter Tight;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  &::placeholder {
    color: rgba(59, 59, 59, 0.4);
    font-family: Inter Tight;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
}
.addButton {
  @include blueButton;
  width: fit-content;
  margin-bottom: 40px;
}

.continueButton {
  @include blueButton;
  align-self: flex-end;
  justify-self: flex-end;
}

.saveButton {
  @include blueButton;
  margin-right: auto;
}

.backButton {
  @include whiteButton;

  align-self: flex-end;
  justify-self: flex-end;
}

.botForm {
  display: flex;
  flex-direction: column;
  padding-top: 24px;
}

.dropZone {
  border-radius: 8px;
  border: 2px dashed rgba(59, 59, 59, 0.4);
  padding: 18px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info {
  color: rgba(59, 59, 59, 0.64);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Inter Tight';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  margin: 0;
  margin-top: 8px;
}

.fileList {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.fileItem {
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;

  div {
    display: flex;
    align-items: center;
  }
  &:hover {
    background: #f3f3f3;
    .fileName {
      color: #3b3b3b;
    }
  }
}

.fileName {
  margin: 0;
  color: rgba(59, 59, 59, 0.6);
  font-family: 'Inter Tight';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-left: 8px;
}

.fileLoad {
  margin: 0;
  color: rgba(59, 59, 59, 0.4);
  font-family: 'Inter Tight';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 123.077% */
  margin-right: 40px;
}

.fileDelete {
  color: rgba(59, 59, 59, 0.6);
  font-family: 'Inter Tight';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  border: none;
  background: transparent;
  &:hover {
    cursor: pointer;
  }
}

.tabButtons {
  display: flex;
  gap: 24px;
  width: 100%;
  justify-content: flex-end;
}

.canalsList {
  margin: 0;
  padding: 0;
  padding-top: 20px;
  display: flex;
  align-items: center;
  gap: 24px;
  list-style: none;
  flex-wrap: wrap;
  margin-bottom: 24px;
}

.canalItem {
  border: none;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.04);
  padding: 19px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex-direction: column;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.12);
  }
}

.canalText {
  margin: 0;
  text-align: center;
  width: 246px;
}

.canalImg {
  width: 50px;
  height: 50px;
}
.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  &:not(:last-of-type) {
    margin-bottom: 18px;
  }
}

.label {
  color: #3b3b3b;
  font-family: Inter Tight;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.text {
  min-height: 128px;
  min-width: 100%;
  max-width: 70vw;
  max-height: 50vh;
  padding: 12px;
  color: #000;
  font-family: Inter Tight;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  &::placeholder {
    color: rgba(59, 59, 59, 0.4);
    font-family: Inter Tight;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
}
.select {
  width: 100%;
}

.addSite {
  display: flex;
  width: 100%;
}

.deleteButton {
  border: none;
  background: transparent;

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
}
