@import "../../../styles/styles.scss";

.cancelChange {
  width: 486px !important;
  .ant-modal-content {
    border-radius: 20px;
    height: 352px;
    width: 486px;
    padding: 50px 30px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .ant-modal-title {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 6px;
    text-align: center;
  }
  .ant-modal-body {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    .continue {
      @include blueButton;
      font-weight: 700;
      margin-top: 14px;
      width: 310px;
      display: inline-block;
    }
    .cancel {
      @include whiteButton;
      width: 310px;

      font-weight: 700;
      margin-top: 14px;
    }
  }
}
