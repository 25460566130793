.button {
	button {
		width: 310px;
		height: 46px;
		border-color: #3B3B3B;
		border-radius: 10px;
		background: #fff;
		font-size: 16px;
		color: #3B3B3B;
	}
}
