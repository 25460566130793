.container {
  position: fixed;
  background: #fff;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.04);
  height: 100%;
  min-width: 256px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 1;
}
.main {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 17px 10px 20px 10px;
}
.logo {
  cursor: pointer;
  height: 56px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(59, 59, 59, 0.12);
  padding-top: 8px;
}
.chatbots {
  cursor: pointer;
  border-radius: 8px;
  background: linear-gradient(90deg, #4652a0 0%, #57b1c7 100%);
  display: flex;
  gap: 12px;

  height: 40px;
  padding: 8px;
  color: #fff;
  font-family: Inter Tight;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  img {
    margin-right: 12px;
  }
}

.bottomContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  padding-bottom: 24px;
}

.messagesRemain {
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    width: 100%;
    margin: 0;
    color: rgba(59, 59, 59, 0.6);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      span {
        color: #3b3b3b;
        font-weight: 700;
      }
    }
  }
}
.help {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border: none;
  background-color: transparent;

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
  p {
    color: #3b3b3b;
    font-family: Inter Tight;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    margin: 0;
  }
}
.footer {
  padding: 15px 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-top: 1px solid rgba(59, 59, 59, 0.12);
  font-size: 12px;
  p {
    margin: 0;
  }
}
