@import "../../../styles/styles.scss";

.tokenModal {
  width: 486px !important;
  position: relative;
  .ant-modal-content {
    border-radius: 20px;

    width: 486px;
    padding: 32px 24px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
  }
  .ant-modal-title {
    font-size: 22px;
    margin-bottom: 6px;
  }
  .closeButton {
    @include closeButton;
  }
  .labelText {
    font-family: Inter Tight;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
  .tokenInput {
    margin-top: 8px;
    width: 100%;
    background: #fff;
    height: 46px;
    border-radius: 8px;
    border: 1px solid #3b3b3b66;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 10px;
  }

  p {
    font-family: Inter Tight;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  .ant-modal-body {
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    .note {
      display: flex;

      padding: 8px 16px;
      line-height: 12px;

      border-radius: 10px;
      font-size: 12px;
      color: #865dcf;

      background: #e8e0f8;

      img {
        width: 24px;
        margin-right: 10px;
      }
      p {
        font-family: Inter Tight;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;

        margin: 0;
      }
    }
    .stepsTitle {
      margin-top: 30px;
      font-weight: 700;
    }
    .steps {
      padding: 0 20px;
      li {
        margin: 20px 0;
      }
    }
    .help {
      position: relative;
      left: calc(50% - 155px);
      width: 310px;
      height: 46px;
      color: #3b3b3b;
      border: 1px solid #3b3b3b;
      border-radius: 10px;
      font-size: 16px;
      font-weight: 700;
    }
  }

  .sliderContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    margin-bottom: 16px;
    p {
      white-space: nowrap;
      margin: 0;
      font-family: Inter Tight;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  .buttonContainer {
    display: flex;
    align-items: center;
    gap: 24px;
    align-items: flex-end;
    width: 100%;
  }
}

.whiteButton {
  width: fit-content;
  @include whiteButton;
}
.blueButton {
  width: fit-content;
  @include blueButton;
}

.hidden {
  display: none;
}
