.listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid rgba(59, 59, 59, 0.08);
  background: #fff;
  margin-bottom: 16px;
  &:hover {
    cursor: pointer;
    border: 1px solid rgba(59, 59, 59, 0.2);
  }
  div {
    display: flex;
    align-items: center;
    img {
      margin-right: 24px;
    }
  }
}

.messages {
  color: #b8b8b8;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-align: right;
  margin: 0;
  margin-right: 62px;
  span {
    color: rgba(59, 59, 59, 0.8);
    font-weight: 500;
  }
}

.button {
  display: flex;
  align-items: center;
  gap: 7px;
  color: rgba(59, 59, 59, 0.8);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
