.creationLoader {
	width: 486px !important;
	.ant-modal-content {
		border-radius: 20px;
		height: 486px;
		width: 486px;
		padding: 50px 30px 30px;
		display: flex;
		flex-direction: column;
 	}
	.ant-modal-title  {
		font-size: 24px;
		line-height: 30px;
		margin-bottom: 6px;
		text-align: center;
	}
	.ant-modal-body {
		font-size: 16px;
		line-height: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		p {
			margin-bottom: 80px;
		}
		img {
			width: 100px;
			-webkit-animation: spin 4s linear infinite;
    		-moz-animation: spin 4s linear infinite;
    		animation: spin 4s linear infinite;
		}
		@-moz-keyframes spin {
			100% { -moz-transform: rotate(360deg); }
		}
		@-webkit-keyframes spin {
			100% { -webkit-transform: rotate(360deg); }
		}
		@keyframes spin {
			100% {
				-webkit-transform: rotate(360deg);
				transform:rotate(360deg);
			}
		}
	}
}
