.button {
	margin-bottom: 8px;
	button {
		width: 310px;
		height: 46px;
		border-color: none;
		border-radius: 10px;
		background: linear-gradient(to right,#464E9E, #58B7CC);
		font-size: 16px;
		color: #fff;
	}
}
