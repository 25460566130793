.сreationError {
	width: 486px !important;
	.ant-modal-content {
		border-radius: 20px;
		height: 486px;
		width: 486px;
		padding: 50px 30px 30px;
		display: flex;
		flex-direction: column;
 	}
	.ant-modal-title  {
		font-size: 24px;
		line-height: 30px;
		margin-bottom: 6px;
		text-align: center;
	}
	.ant-modal-body {
		font-size: 16px;
		line-height: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		.error {
			list-style: none;
			display: flex;
			flex-direction: column;
			width: 420px;
			padding: 0 16px 8px;
			margin-bottom: 60px;
			line-height: 12px;
			border: 1px solid #DB5A5A;
			border-radius: 10px;
			font-size: 12px;
			color: #DB5A5A;
			background: #FFE2E2;
			li {
				display: flex;
				margin-top: 8px;
				img {
					width: 10px;
					margin-right: 10px;
				}
				p {
					margin: 0;
				}
			}

		}
		.continue {
			width: 310px;
			height: 46px;
			color: #FFF;
			background: linear-gradient(to right,#464E9E, #58B7CC);
			border-radius: 10px;
			font-size: 16px;
			font-weight: 700;
			margin-top: 14px;
		}
		.help {
			width: 310px;
			height: 46px;
			color: #3B3B3B;
			border: 1px solid #3B3B3B;
			border-radius: 10px;
			font-size: 16px;
			font-weight: 700;
			margin-top: 14px;
		}
	}
}
