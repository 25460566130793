@import '../../styles/styles.scss';
.main_container {
  height: 100vh;
  display: flex;
  width: 100%;
  background-color: #e7e7e7;
}

.inner_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 256px;
}
.main {
  height: 100%;
  background: #fafafa;
  padding: 20px 40px;
  padding-top: 76px;
  overflow: auto;
}
.mainHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}
.title {
  margin: 0;
  margin-bottom: 24px;
  color: #3b3b3b;
  font-family: 'Inter Tight';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
}

.button {
  width: 32px;
  height: 32px;
  background-color: #fff;
}

.createBot {
  @include blueButton;
}
.loader {
  margin-top: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100px;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
  }
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
